import React, {useEffect, useState, Fragment} from 'react';
import {db} from '../firebase'
import Moment from 'moment';
import {toast} from 'react-toastify';
import {Modal, Button} from 'react-bootstrap';
import Navigation from './Navigation'
import {auth} from '../firebase'
import { withRouter } from "react-router-dom"
import useLocalStorage from '../hooks/useLocalStorage';


function Quotes (props) {

    const [user, setUser] = useLocalStorage('user', null)

    useEffect ( () => {

        if (auth.currentUser){
            setUser(auth.currentUser)
        } else {
            props.history.push('/login')
        }

    }, [props.history, setUser])    

    const {settings, setSettings} = props;

    const [quotes, setQuotes] = useState([])
    const [quoteId, setQuoteId] = useState('')
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = id => {
        setShow(true);
        setQuoteId(id);
    }


    const setQuoteAsCurrent = () => {

        const findQuote = quotes.find((quote) => quote.id === quoteId)

        setSettings(findQuote.quote_info)
        handleClose();

        toast.success('Propuesta cargada', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });


    }

    useEffect(() => {

        const getQuotes = async () => {
            try {
                const data = await db.collection('quotes').get()
                const arrayData = data.docs.map(doc => ({id: doc.id, ...doc.data()}))
                const orderedArray = arrayData.sort((a, b)=>{
                    return (a.date < b.date) ? 1 : -1
                });
                orderedArray.length > 0 && setQuotes (orderedArray)
            } catch (error) {
                console.log(error)
            }
        }
        getQuotes()

    }, [])

return (

    <Fragment>

    <Navigation></Navigation>

    <div className="container">

        <div className="title_wrapper">
                <div className="area_title">Cotización de Servicio Pulpou Market Analyst</div>
        </div>



            <div className="card mt-5 mb-4 p-4">

            <table className="table quotes_table">

                <thead>
                        <tr>
                            <th className="text-center" scope="col" ></th>
                            <th className="text-center" scope="col"></th>
                            <th className="text-start" scope="col">Fecha</th>
                            <th className="text-start" scope="col">Comercial</th>
                            <th className="text-start" scope="col">Propuesta Comercial </th>
                            <th className="text-start" scope="col">Empresa</th>
                            <th className="text-center" scope="col">Sitios</th>
                            <th className="text-end" scope="col">$ Mensual</th>
                            <th className="text-end" scope="col">$ Semestral</th>
                            <th className="text-end" scope="col">$ Anual</th>
                        </tr>
                </thead>
                <tbody>
                { quotes.map((quote) => (
                        <tr key={quote.id}>
                            <td className="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="quotes_icons bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </td>
                            <td className="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>(handleShow(quote.id))} width="16" height="16" className="quotes_icons" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"/>
                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                                </svg>
                            </td>
                            <td className="text-start">{Moment(quote.date).format('d MMM, yy')}</td>
                            <td className="text-start">{quote.quote_info.sellerName} {quote.quote_info.sellerLastName}</td>
                            <td className="text-start">{quote.quote_info.quoteName}</td>
                            <td className="text-start">{quote.quote_info.company}</td>
                            <td className="text-center">{quote.cart.length}</td>
                            <td className="text-end">$ </td>
                            <td className="text-end">$ </td>
                            <td className="text-end">$ </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            </div>


    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Descarga de Propuesta Comercial</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseas descargar esta propuesta? <p>Se perderán todos los datos actuales de seteos y carro de sitios.</p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={setQuoteAsCurrent}>
            Descargar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>

</Fragment>

)

};

export default withRouter(Quotes);
