import React, {Fragment, useEffect} from 'react';
import SiteCard from "./SiteCard";
import {getCountryName} from './utils.js';
import useLocalStorage from '../hooks/useLocalStorage';
import uuid from 'react-uuid';


function GetSites (props) {


    const countryName = getCountryName(props.countryinfo.country);
    const [sites, setSites] = useLocalStorage('sites', []);
    const flag = "https://www.countryflags.io/"+props.countryinfo.country.toLowerCase()+"/flat/64.png";
    const idAccordionControl = 'country'+props.countryinfo.country;
    const idAccordion = "#"+idAccordionControl;

    useEffect(function effectFunction() {
        async function fetchSites() {
            const response = await fetch('https://api-scrap.pulpou.com:9393/site/getAllSites?countryCode='+props.countryinfo.country);
            const json = await response.json();
            setSites(json);
        }
        fetchSites();
    }, [props, setSites]);
    
    
    return (

    <Fragment>
        
    <div className="accordion-item" key={uuid()}>
            
            <h2 className="accordion-header" id={props.countryinfo.country}>
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={idAccordion} aria-expanded="false" aria-controls={idAccordionControl}>
            <img className="country-flag" src={flag} alt={countryName.toUpperCase()}/> {countryName.toUpperCase()} 
            </button>
            </h2>

            <div id={idAccordionControl} className={(props.countryinfo.show) ? 'show' : ''} aria-labelledby="headingOne" data-bs-parent="sitesAccordion">
                <div className="accordion-body">  

                        <SiteCard 
                        sites = {sites} 
                        selectedSites = {props.selectedSites} 
                        setSelectedSites = {props.setSelectedSites}
                        setTotalPrice = {props.setTotalPrice}
                        settings = {props.settings}
                        />                    

                </div>
            </div>
        </div>


    </Fragment>
    
    );
 }

 export default GetSites;   

