import React, {Fragment} from 'react';
import {withRouter} from "react-router-dom"
import {NavLink} from "react-router-dom";
import {auth} from '../firebase';

const Navigation = (props) => {

    const exit = () => {
        auth.signOut()
        .then (() => {
            props.history.push('/login')
        })
    }


    return (
       <Fragment>

        <div className="container">

            <div className="navigation_bar">
                <div className="d-flex align-items-stretch mr-3">
                <div className="logo" ><img src="../logo.png" alt="Pulpou"/></div>
                <ul className="nav_menu">
                    <NavLink to="/root"><li>Cotización</li></NavLink>
                    <NavLink to="/settings"><li>Seteos</li></NavLink>
                    <NavLink to="/quotes"><li>Propuestas</li></NavLink>
                </ul>
                </div>
                <div className="user_menu">
                    <div className="greeting"><span>Hola, </span>Gabriel</div> 
                    <div className="avatar">GP</div>
                    <div className="closeSession">
                    <div className="close_session_wrapper" onClick={exit}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="close_session" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                        </svg>
                    </div>


                    </div>
                </div>

            </div>

        </div>

        <div className="hr_divider"></div>

       </Fragment>
    );
}
 
export default withRouter(Navigation);