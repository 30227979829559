import React, {Fragment, useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Navigation from './Navigation'
import {auth} from '../firebase'
import { withRouter } from "react-router-dom"

const Settings = (props) => {


    const [user, setUser] = useLocalStorage('user', null)

    useEffect ( () => {

        if (auth.currentUser){
            setUser(auth.currentUser)
        } else {
            props.history.push('/login')
        }

    }, [props.history, setUser])    

    const { register, handleSubmit } = useForm();
    const {settings, setSettings} = props;
   
    const updateCheck = (event) => {
        console.log(event.target.checked);
        setSettings ({
            ...settings,
            showDiscount: event.target.checked
        })
    }


    const onSubmit = data => {

        const newSettings = 
            { 
                quoteName: data.quoteName,
                quoteNumber: data.quoteNumber,
                cost: parseInt(data.cost),
                costAdHoc: parseInt(data.costAdhoc),
                showDiscount: data.showDiscount,
                name: data.name,
                lastName: data.lastName,
                position: data.position,
                email: data.email,
                company: data.company,
                country: data.country,
                sellerName: data.sellerName,
                sellerLastName: data.sellerLastName,
                sellerEmail: data.sellerEmail,
                sellerPosition: data.sellerPosition,
                sellerCompany: data.sellerCompany,
                sellerCountry: data.sellerCountry
            }
        

        setSettings (newSettings); 

        toast.success('Seteos actualizados', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

        }

    return (
        <Fragment>

        <Navigation></Navigation>


        <div className="container">

            <div className="title_wrapper">
                    <div className="area_title">Cotización de Servicio Pulpou Market Analyst</div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="quotes-area card mt-5 mb-4 p-4">
                    
                    
                        <div>
                            <h5>Gestión de Shopping Cart</h5>
                        </div>

                        <div className="row">

                        <div className="col-md-4  pt-4 pb-4">
                        
                        <label>Costo sitio Pre-Escaneado </label>

                        <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">$</span>
                                <input defaultValue={settings.cost} {...register("cost", { required: true })} type="number" className="price_input form-control" aria-label="costoSitio" aria-describedby="basic-addon1" />
                            </div>
                        </div>

                        <div className="col-md-4 pt-4 pb-4">
                            <label>Costo sitio Ad-Hoc </label>
                        
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">$</span>
                                <input defaultValue={settings.costAdHoc} {...register("costAdhoc", { required: true })} type="number" className="price_input form-control" aria-label="costoAdHoc" aria-describedby="basic-addon1" />
                            </div>
                        </div>

                        <div className="col-md-12">                  
                            <div className="form-check">
                                <input {...register("showDiscount")} 
                                className="form-check-input" type="checkbox"
                                checked={settings.showDiscount}
                                onChange={updateCheck}
                                />
                                <label className="form-check-label">Activar descuento de abono</label>
                            </div>
                        </div>  

                        </div>

                    <hr className="divider"></hr>

                    <div className="row mt-2 mb-5">
                    
                        <div className="mb-2">
                            <h5>Propuesta Comercial</h5>
                        </div>  

                        <div className="col-md-6">
                            <label>Nombre propuesta comercial</label>
                            <input defaultValue={settings.quoteName} className="form-control" {...register("quoteName", { required: true })}  type="text"></input>
                        </div> 
                        <div className="col-md-3">
                            <label>Nro. propuesta comercial</label>
                            <input defaultValue={settings.quoteNumber} className="form-control" {...register("quoteNumber", { required: true })}  type="text" value={settings.quoteNumber} ></input>
                        </div>

                    </div>


                    <div className="row">

                        <div className="col-md-6">

                                <div>
                                    <h5>Datos de Contacto</h5>
                                </div>   

                                <div className="col-md-8 col-lg-8">
                                    <label>Nombre</label>
                                    <input defaultValue={settings.name} {...register("name", { required: true })}  className="form-control" type="text" ></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Apellido</label>
                                    <input defaultValue={settings.lastName} {...register("lastName", { required: true })}  className="form-control" type="text" ></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Email</label>
                                    <input defaultValue={settings.email} {...register("email", { required: true })}  className="form-control" type="text" ></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Cargo</label>
                                    <input defaultValue={settings.position} {...register("position", { required: true })}  className="form-control" type="text" ></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Empresa</label>
                                    <input defaultValue={settings.company} {...register("company", { required: true })} className="form-control" type="text" ></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>País</label>
                                    <input defaultValue={settings.country} {...register("country", { required: true })} className="form-control" type="text" ></input>
                                </div>
                                

                        </div>
                        
                        <div className="col-md-6">

                                <div>
                                    <h5>Contacto Comercial</h5>
                                </div>   

                                <div className="col-md-8 col-lg-8">
                                    <label>Nombre</label>
                                    <input defaultValue={settings.sellerName} {...register("sellerName", { required: true })}  className="form-control" type="text"></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Apellido</label>
                                    <input defaultValue={settings.sellerLastName} {...register("sellerLastName", { required: true })}  className="form-control" type="text"></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Email</label>
                                    <input defaultValue={settings.sellerEmail} {...register("sellerEmail", { required: true })}  className="form-control" type="text"></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Cargo</label>
                                    <input defaultValue={settings.sellerPosition} {...register("sellerPosition", { required: true })} className="form-control" type="text"></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>Empresa</label>
                                    <input defaultValue={settings.sellerCompany} {...register("sellerCompany", { required: true })} className="form-control" type="text"></input>
                                </div>

                                <div className="col-md-8 col-lg-8">
                                    <label>País</label>
                                    <input defaultValue={settings.sellerCountry} {...register("sellerCountry", { required: true })} className="form-control" type="text"></input>
                                </div>


                        </div>                    

                        <div className="col-md-12">        
                                
                                <hr></hr>

                                <button type="submit" className="btn btn-secondary">
                                    Guardar propuesta comercial
                                </button>

                        </div>         

                        </div>

                </div>

            </form>

        </div>
        
        </Fragment>
    )
};

export default withRouter(Settings);
