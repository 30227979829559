import React, {useCallback} from 'react'
import { withRouter } from "react-router-dom"
import {auth} from '../firebase'

const Login = (props) => {


    const [login_email, setEmail] = React.useState('')
    const [login_pass, setPass] = React.useState('')
    const [error, setError] = React.useState(null)


    const processLogin = e => {
        e.preventDefault()
        console.log ("datos : "+login_email+" "+login_pass)
        if (!login_email.trim()){
            setError("Ingresa Email")
            return
        }
        if (!login_pass.trim()){
            setError("Ingresa Contraseña")
            return
        }
        if (login_pass.length < 6){
            setError("Contraseña demasiado corta")
            return
        }     
        setError (null)   
        login()
    }


    const login = useCallback(async() => {
       
        try{

            await auth.signInWithEmailAndPassword(login_email, login_pass)  
            setEmail('')
            setPass('')
            setError(null)
            props.history.push('/root')

        }
        catch (error) {
            if(error.code === 'auth/user-not-found'){
                setError('Usuario o contraseña incorrecta')
            }
            if(error.code === 'auth/wrong-password'){
                setError('Usuario o contraseña incorrecta')
            }
            console.log(error.code)
            console.log(error.message)
        }

        }, [login_email, login_pass, props.history]

    )
    

    return (


    <div className="login_wrapper"> 

       <div className="login_card">

           <img src="logo.png" className="login_logo" alt="Pulpou"/>

           <h5>Market Analyst Cotizador</h5>
           <p>Ingreso reservado</p>
 
            <div className="row justify-content-center">
                <div>
                    <form onSubmit={processLogin}>
                        {
                            error && (
                               <div className="alert alert-danger">{error}</div> 
                            )
                        }
                        <input 
                            type="email" 
                            name="login_email"
                            className="form-control mb-2"
                            placeholder="Ingresa Email"
                            onChange = {e => setEmail(e.target.value)}
                            value={login_email}
                        />
                        <input 
                            type="password" 
                            name="login_pass"
                            className="form-control mb-2"
                            placeholder="Ingresa Contraseña"
                            onChange = {e => setPass(e.target.value)}
                            value={login_pass}
                        />
                        <button 
                            className="btn btn-primary btn-block btn-sm"
                            type="submit"
                        >
                            Ingresar
                        </button>

                    </form>
                </div>
            </div>
        </div>
        
    </div>

    )
}

export default withRouter(Login)
