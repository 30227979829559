import React, {useState, Fragment} from 'react';
import uuid from 'react-uuid';
import Settings from './Settings.js';
import {updateFinalPrices} from './utils.js';



function SiteCard(props) {

    const {settings} = props;
    const [currentSite, setCurrentSite] = useState([]);  

    const addSiteToCart = (id) => {

        // first, let's check if site already in the cart

        const selected = props.sites.find((site) => site.id === id);
        const already = props.selectedSites.find((site) => site.id === id);
        setCurrentSite(selected);
        
        if (already){

            //already in Cart, exit
            return

        } else { 

            const currentSite = props.sites.find((site) => site.id === id);
            const addSite = [
                ...props.selectedSites,
                { 
                id: id, 
                site: currentSite.name,
                image: currentSite.urlImgSquare,
                credits: currentSite.credits,
                extraCredits: 0,
                additionalCost: 0,
                cost: settings.cost,
                baseCost: 50,
                country: currentSite.country
                }
            ]

            props.setSelectedSites (addSite);
            
            // Update final prices
            const cartSites = [...props.selectedSites];
            props.setTotalPrice (updateFinalPrices (cartSites)); 

        }
    }

    function isAdded(id){

        const checkSite = props.selectedSites.find((web) => web.id === id);
        
        if ( checkSite !== undefined){
            return ('bi bi-check-square-fill is_added');
        } else {
            return ('bi bi-check-square-fill not_added');
        }

    }

    function isAddedCard(id){

        const checkSite = props.selectedSites.find((web) => web.id === id);
        
        if ( checkSite !== undefined){
            return ('card site_car card_added');
        } else {
            return ('card site_car');
        }

    }


    return (

        <Fragment>        
            
            <div id="cardsWrapper"  className="row row-cols-1 row-cols-md-3 g-4 mt-2 mb-2" key={uuid()}>
                {
                props.sites.map((site) => (

                    <div className="col" key={uuid()}>
                        <div className={isAddedCard(site.id)}>
                            
                            <div className="site_added">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className={isAdded(site.id)}  viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                                </svg>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <img className="img-fluid rounded-start border-end site_image" src={site.urlImgSquare} alt={site.name} onClick = { () => addSiteToCart(site.id) }/>
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <div className="site_name">{site.name}</div>
                                        <div className="site_url">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                                            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                                            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                                            </svg>
                                            <a href={site.baseUrl} rel="noreferrer" target="_blank"> link</a>
                                            </div>
                                        <div className="card_divider"></div>
                                        {                            
                                            Object.values(site.searchType).map((type) => (
                                            <span className="search_type-tag" key={uuid()}>{type.value}</span>
                                        
                                            ))
                                        }   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                ))
                }

            </div>

        </Fragment>

    )}

    export default SiteCard;   
