import React, {Fragment} from 'react';
import CustomSiteCard from "./CustomSiteCard";
import { useForm } from "react-hook-form";


function CustomSites(props) {

        const {register, handleSubmit, reset} = useForm();
        const {selectedSites, setSelectedSites, CustomSitesInCart, SetCustomSitesInCart} = props;
        
        const onSubmit = data => {

            //check if site already added to the cart
            const already = selectedSites.find((site) => site.url === data.siteUrl);
            if (already === undefined){

                const addCustomSite = [
                    ...selectedSites,
                    {
                        id: data.siteUrl, 
                        url: data.siteUrl,
                        image: '',
                        credits: 15000,
                        site: data.siteName,
                        extraCredits: 0,
                        additionalCost: 0,
                        cost: 100,
                        baseCost: 50,
                        country: 'N/D'
                    }
                ];

                setSelectedSites (addCustomSite); 

                const addCustomSiteList = [
                    ...CustomSitesInCart,
                    {
                        id: data.siteUrl, 
                        url: data.siteUrl,
                        name: data.siteName
                    }
                ];

                SetCustomSitesInCart (addCustomSiteList); 

                reset();

            }
        } 

    
return (

    <Fragment>

        <form  onSubmit={handleSubmit(onSubmit)}>
            <input placeholder="Nombre del sitio" className="custom_site_field" {...register("siteName", { required: true })} />
            <input placeholder="URL del sitio" size="40" type="url" className="custom_site_field" {...register("siteUrl", { required: true })} />
            <button className="btn btn-outline-secondary btn-sm" type="submit" >Agregar sitio Ad-Hoc</button>
        </form>

        <hr></hr>

    {
        
        CustomSitesInCart.map((site) => (
            <CustomSiteCard 
                site={site}
                CustomSitesInCart = {CustomSitesInCart}
            >
            </CustomSiteCard>
        ))
    }
    
    </Fragment>

);

}


export default CustomSites;