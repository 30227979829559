import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBZEsvs0AY2jxNb6LTQn_6_PrVfxfyGPvg",
    authDomain: "pulpou-ma-quoter.firebaseapp.com",
    projectId: "pulpou-ma-quoter",
    storageBucket: "pulpou-ma-quoter.appspot.com",
    messagingSenderId: "810451817094",
    appId: "1:810451817094:web:67b3b6839c29516bd47707"
  };
   
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
const auth = firebase.auth()

export {db, auth};

