import React, {Fragment} from 'react';

function CustomSiteCard(props) {

    const {site, SelectedSites} = props;


    return (
            
            <div key={site.url} className="custom_site_wrapper">{site.name} - {site.url}</div>
    
        );

    }
    
    
export default CustomSiteCard;