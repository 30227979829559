import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import useLocalStorage from './hooks/useLocalStorage';
import Root from './components/Root';
import Settings from './components/Settings';
import Quotes from './components/Quotes';
import Login from './components/Login';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {auth} from './firebase'



function App() {

  const[firebaseUser, setFirebaseUser] = useState (false)

  const [selectedSites, setSelectedSites] = useLocalStorage('selectedSites', []);
  const [totalPrice, setTotalPrice] = useLocalStorage('totalPrice', 0);
  const [customSitesList, setCustomSitesList] = useLocalStorage('customSitesList', []);
  const [customSitesInCart, setCustomSitesInCart] = useLocalStorage('customSitesInCart', []);
  const [settings, setSettings] = useLocalStorage('settings', {});


  useEffect(()=>{

    auth.onAuthStateChanged(user => {
      if(user){
        setFirebaseUser(user)
      }else{
        setFirebaseUser(null)
      }
    })

  },[])

  return firebaseUser !== false ? (

    <Router>

    <ToastContainer />

    <div className="main_wrapper">

      <Switch>

            <Route path="/root" exact>
              <Root
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}   
              customSitesList={customSitesList}
              setCustomSitesList={setCustomSitesList}
              customSitesInCart={customSitesInCart}
              setCustomSitesInCart={setCustomSitesInCart}
              settings={settings}
              setSettings={setSettings} 
              />
            </Route>

            <Route path="/login">
              <Login
              />
            </Route>

            <Route path="/settings">
              <Settings 
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}   
              customSitesList={customSitesList}
              setCustomSitesList={setCustomSitesList}
              customSitesInCart={customSitesInCart}
              setCustomSitesInCart={setCustomSitesInCart}
              settings={settings}
              setSettings={setSettings} 
              />
            </Route>

            <Route path="/quotes">
              <Quotes 
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}
              settings={settings}
              setSettings={setSettings}             
              />
            </Route>          
      </Switch>



    </div>


    
  </Router>


  ) : (

    <p>CARGANDO ...</p>

  )
}

export default App;
