import React, {Fragment, useEffect} from 'react';
import {updateFinalPrices} from './utils.js';
import useLocalStorage from '../hooks/useLocalStorage';
import {db} from '../firebase'
import {toast} from 'react-toastify';
import Moment from 'moment';


function Cart(props) {

    const {
        settings,  
        selectedSites, setTotalPrice, 
        customSitesInCart} = props;
    const [discountPercentage, setDiscountPercentage] = useLocalStorage('discountPercentage', 0);
    const [totalUndiscountedPrice, setTotalUndiscountedPrice] = useLocalStorage('totalUndiscountedPrice', 0);

    useEffect(() => {
    
        // Update final prices
        const cartSites = [...selectedSites];
        const finalPrice = updateFinalPrices(cartSites);
        setTotalUndiscountedPrice (finalPrice); 
        setTotalPrice (finalPrice  - ( finalPrice * (discountPercentage / 100))); 
    
    }, [selectedSites, discountPercentage, setTotalUndiscountedPrice, setTotalPrice]);

    const addQuote = async () => {
        try {
            await db.collection('quotes').add({
                date: Date.now(),
                quote_info: settings,
                cart:  selectedSites
            })

            toast.success('Propuesta guardada', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        } catch (error) {
            console.log(error)
        }
    }

    function addCredits(credits, id) {

        const currentSite = selectedSites.filter((site) => site.id === id);
        const newCredits = currentSite[0].extraCredits + parseInt(credits);
        const additionalCost = (newCredits / currentSite[0].credits ) * 50;
        const currentSiteNewValues = [
            {   
                id: currentSite[0].id, 
                site: currentSite[0].site, 
                image: currentSite[0].image, 
                credits: currentSite[0].credits, 
                extraCredits: newCredits, 
                additionalCost: additionalCost, 
                baseCost: currentSite[0].baseCost,
                cost: (currentSite[0].cost + currentSite[0].baseCost), 
                country: currentSite[0].country
            }
        ];

        // Update final array with new values only in corresponding ID
        const arrayEditado = selectedSites.map (site => site.id === id ? currentSiteNewValues[0] : site);

        // Update state of selectedSites using setSelectedSites
        props.setSelectedSites(
            arrayEditado
        );

    }


    function restCredits(credits, id) {

        const currentSite = selectedSites.filter((site) => site.id === id);
        const newCredits = currentSite[0].extraCredits - parseInt(credits);

        if (newCredits < 0) { return } 
        
        else {

            const additionalCost = (newCredits / currentSite[0].credits ) * currentSite[0].baseCost;
            const currentSiteNewValues = [
                {   
                    id: currentSite[0].id, 
                    site: currentSite[0].site, 
                    image: currentSite[0].image, 
                    credits: currentSite[0].credits, 
                    extraCredits: newCredits,
                    baseCost : currentSite[0].baseCost,
                    additionalCost: additionalCost, 
                    cost: (currentSite[0].cost - currentSite[0].baseCost), 
                    country: currentSite[0].country
                }
            ];

            // Update final array with new values only in corresponding ID
            const editedSites = selectedSites.map (site => site.id === id ? currentSiteNewValues[0] : site);

            // Update state of selectedSites using setSelectedSites
            props.setSelectedSites(
                editedSites
            );

        }

    }
 

    function deleteSelectedCountry(id){
        props.setSelectedSites(selectedSites.filter((site) => site.id !== id));
        props.setCustomSitesInCart(customSitesInCart.filter((site) => site.id !== id));
        
    }

  return (

                            <Fragment>
                            <div className="mt-5  mb-2 d-flex justify-content-between">
                            
                                 <div className="cart_quote_name">{settings.quoteName} #{settings.quoteNumber}</div>
                                 <div className="cart_quote_date">{Moment(Date.now()).format('d MMM, yy')}</div>
                                 
                            </div>
                           
                            <div className="content-wrapper">
                                <table className="table sites_cart_table">
                                <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th className="text-start" scope="col">Sitio</th>
                                            <th className="text-center" scope="col">País</th>
                                            <th className="text-end" scope="col">Créditos</th>
                                            <th className="text-end" scope="col">Créditos Extras</th>
                                            <th className="text-end" scope="col">Créditos Totales</th>
                                            <th className="text-end" scope="col">$ Adicional</th>
                                            <th className="text-end" scope="col">$ Final</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-striped">

                                    {

                                    selectedSites.length > 0 ? (

                                        selectedSites.map((site) => (

                                            <tr key={site.id}>
                                                <th scope="row">
                                                    <svg onClick={() => deleteSelectedCountry(site.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="close bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                    </svg>
                                                </th>
                                                <td className="text-start site_name" >
                                                    {site.image !== '' ? <img src={site.image} className = "cart_site_image" alt={site.site}/> : <img src="https://img.icons8.com/cotton/2x/website.png" className="cart_site_image" alt="ad-hoc site" />}
                                                    {site.site}
                                                    </td>
                                                <td className="text-center" >{site.country}</td>
                                                <td className="text-end">{site.credits.toLocaleString()}</td>
                                                <td className="extra_credits">
                                                    <div className="modify_credits" onClick={() =>addCredits(site.credits, site.id)}>+</div>
                                                    {site.extraCredits.toLocaleString()}
                                                    <div className="modify_credits" onClick={() =>restCredits(site.credits, site.id)}>-</div>
                                                </td>
                                                <td className="text-end">{ (site.credits + site.extraCredits).toLocaleString()  }</td>
                                                <td className="text-end">${site.additionalCost}</td>
                                                <td className="text-end">${site.cost}</td>
                                            </tr>


                                        ))
                                    

                                    ) : (

                                        <tr>
                                        <td colSpan="8" className="text-center">Selecciona sitios para comenzar la cotización</td>
                                    </tr>  

                                    )

                                    }
                                  
                                        <tr>
                                            <th colSpan="6" className="text-end"></th>
                                            <th className="text-end">Descuento</th>
                                            <th className="text-end">Precio Total</th>
                                        </tr>                                        
                                        <tr>
                                            <td colSpan="6" className="text-end">Abono Mensual</td>
                                            <td className="text-end">
                                            
                                                 <div className={ settings.showDiscount ? '' : 'hide'}>
                                                    <input id="discount" className="discount" type="number" onChange={ (e) => setDiscountPercentage(e.target.value) }></input> 
                                                     <span className="percentage">%</span> ( <span className="prices">${ Math.round((totalUndiscountedPrice * discountPercentage) / 100) }</span> )
                                                 </div>
                                            </td>
                                            <td className="text-end prices">${props.totalPrice && props.totalPrice.toLocaleString()}</td>
                                        </tr>                                                                            
                                        <tr>
                                            <td colSpan="6" className="text-end">Pago adelantado 6 meses abono semestral</td>
                                            <td className="text-end">5%  ( <span className="prices">${Math.round((props.totalPrice * 0.05)*6)}</span> )</td>
                                            <td className="text-end prices">${Math.round(((props.totalPrice-(props.totalPrice * 0.05)) * 6)).toLocaleString()}</td>
                                        </tr>                                                                                
                                        <tr>
                                            <td colSpan="6" className="text-end">Pago adelantado 12 meses abono anual</td>
                                            <td className="text-end">15%  ( <span className="prices">${Math.round((props.totalPrice * 0.15)*12)}</span> )</td>
                                            <td className="text-end prices">${Math.round(((props.totalPrice-(props.totalPrice * 0.15)) * 12)).toLocaleString()}</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="8" className="text-end">
                                                <button className="btn btn-secondary btn-sm btn_save_quote" onClick={addQuote}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="quote_icon"   viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                                                </svg>
                                                    Guardar propuesta
                                                </button>

                                                <button className="btn btn-secondary btn-sm">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  className="quote_icon" viewBox="0 0 16 16">
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                                </svg>
                                                    Exportar propuesta
                                                </button>                                                
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            </Fragment>
                        
  );
}

export default Cart;
 