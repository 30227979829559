import React, {Fragment, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage';
import Navigation from './Navigation'
import {auth} from '../firebase'
import { withRouter } from "react-router-dom"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";


import GetSites from "./GetSites";
import CustomSites from "./CustomSites";
import Cart from "./Cart";



const Root = (props) => {

    const [user, setUser] = useLocalStorage('user', null)

    useEffect ( () => {

        if (auth.currentUser){
          console.log('existe un usuario')
        } else {
          console.log('no existe un usuario')
          props.history.push('/login')
        }



    }, [])

    const {selectedSites, setSelectedSites, totalPrice, setTotalPrice, customSitesList, setCustomSitesList, customSitesInCart, setCustomSitesInCart, settings, setSettings} = props;

    const siteCountries = [
      {
        country: 'AR',
        show: true
      },
      {
        country: 'BR',
        show: false
      }, 
      {
      country: 'MX',
      show: false
      },
      {
      country: 'CO',
      show: false
      }      
    ]


    return (

    <Fragment>

        
        <Navigation></Navigation>

        <div className="container">

          <div className="title_wrapper">
            <div className="area_title">Cotización de Servicio Pulpou Market Analyst</div>
          </div>



          <Cart 
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}   
          customSitesList={customSitesList}
          setCustomSitesList={setCustomSitesList}
          customSitesInCart={customSitesInCart}
          setCustomSitesInCart={setCustomSitesInCart}
          settings={settings}
          setSettings={setSettings}    
          />

          <div className="section_title">
                  Sitios Ad-Hoc
          </div>       
          <div className="content-wrapper">
          
          <CustomSites
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}       
              CustomSitesInCart={customSitesInCart}
              SetCustomSitesInCart={setCustomSitesInCart}
          ></CustomSites>
          
          </div>

              
          <div className="section_title">
                  Sitios por país
          </div>  

          <div className="content-wrapper">
          
              <div className="accordion accordion-flush" id="sitesAccordion">

              {
                  siteCountries.map((site) => (
                  <GetSites 
                  countryinfo = {{country: site.country, show: site.show}}
                  selectedSites={selectedSites}
                  setSelectedSites={setSelectedSites}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice} 
                  settings={settings}
                  />
                  ))
              }

              </div>

          </div>
        
        </div>

      </Fragment>
    )

}
export default withRouter(Root)